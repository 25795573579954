// axios
import axios from 'axios'
//51.38.57.172:443
//axios.defaults.baseURL = 'http://51.38.57.172:8007/api/'
// axios.defaults.baseURL = 'http://192.168.142.144:8000/api/'
axios.defaults.baseURL = 'https://prendtacom.moozistudio.com/api/'
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`

export default axios.create({

})
